import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import {
	faAlicorn,
	faAngleDown,
	faArrowDown,
	faArrowDownFromLine,
	faArrowLeft,
	faArrowRight,
	faArrowsMaximize,
	faArrowsMinimize,
	faArrowsRotate,
	faArrowsSpin,
	faArrowTurnUp,
	faArrowUp,
	faArrowUpRightFromSquare,
	faAsterisk,
	faAtomSimple,
	faBadgeCheck,
	faBadgerHoney,
	faBalloons,
	faBan,
	faBars,
	faBat,
	faBee,
	faBird,
	faBoltAuto,
	faBookSkull,
	faBullseye,
	faCakeCandles,
	faCatSpace,
	faChampagneGlasses,
	faChartLine,
	faCheck,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircleCheck,
	faCircleExclamation,
	faCircleInfo,
	faCircleNotch,
	faCircleRadiation,
	faCircleX,
	faCity,
	faClock,
	faCloudUpload,
	faCloudDownload,
	faCompactDisc,
	faComputer,
	faCopy,
	faCow,
	faCrab,
	faCreditCard,
	faDeer,
	faDeerRudolph,
	faDeleteRight,
	faDeleteLeft,
	faDesktop,
	faDog,
	faDollarSign,
	faDragon,
	faDuck,
	faEarth,
	faElephant,
	faEnvelope,
	faFaceAngry,
	faFaceAngryHorns,
	faFaceAnguished,
	faFaceDiagonalMouth,
	faFaceDisappointed,
	faFaceDisguise,
	faFaceDizzy,
	faFaceExplode,
	faFaceExpressionless,
	faFaceEyesXmarks,
	faFaceHandOverMouth,
	faFaceHeadBandage,
	faFaceIcicles,
	faFaceParty,
	faFaceRaisedEyebrow,
	faFaceSadCry,
	faFaceScream,
	faFaceSwear,
	faFan,
	faFeather,
	faFile,
	faFileArrowUp,
	faFileImage,
	faFileCircleInfo,
	faFilter,
	faFishBones,
	faFlagCheckered,
	faFloppyDisk,
	faGaugeHigh,
	faGear,
	faGhost,
	faGift,
	faGifts,
	faGlobe,
	faHandHoldingSkull,
	faHandshakeSimple,
	faHippo,
	faHome,
	faImage,
	faImagePortrait,
	faLaptop,
	faLightbulb,
	faLoader,
	faLock,
	faMagnifyingGlass,
	faMagnifyingGlassDollar,
	faMagnifyingGlassMinus,
	faMagnifyingGlassPlus,
	faMaximize,
	faMemoCircleCheck,
	faMinimize,
	faMinus,
	faMobile,
	faMoneyBill,
	faMonkey,
	faNarwhal,
	faNewspaper,
	faOtter,
	faPanorama,
	faPartyBell,
	faPartyHorn,
	faPencil,
	faPeopleGroup,
	faPerson,
	faPhone,
	faVideo,
	faPinata,
	faPlus,
	faPrint,
	faRabbit,
	faRadiation,
	faRam,
	faRepeat,
	faRotate,
	faRotateLeft,
	faRotateRight,
	faScythe,
	faShare,
	faSheep,
	faShuffle,
	faSkull,
	faSkullCow,
	faSkullCrossbones,
	faSpiderBlackWidow,
	faSpinner,
	faSpinnerThird,
	faSquarePen,
	faSquirrel,
	faStar,
	faTablePivot,
	faTablet,
	faThumbsUp,
	faTimes,
	faTombstoneBlank,
	faTrash,
	faTriangleExclamation,
	faTurtle,
	faUser,
	faUserPen,
	faUserPlus,
	faWandMagicSparkles,
	faUsers,
	faVolcano,
	faCircleQuestion,
	faLocationDot,
	faPause,
	faMessageQuestion,
	faBlockQuestion,
	faSquareXmark,
	faCircleM,
	faQuestion,
	faBriefcase,
	faCarBurst,
	faCalendar,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faGoogle,
	faYelp,
	faFacebook,
	faLinkedin,
	faXTwitter,
	faInstagram,
	faMicrosoft,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
	faCircleCheck as thinCircleCheck,
	faCircleArrowRight as thinCircleArrowRight,
	faCircleExclamation as thinCircleExclamation,
	faEarthAmericas as thinEarthAmericas,
} from '@fortawesome/pro-thin-svg-icons';

//add each icon here after importing
[
	faAlicorn,
	faAngleDown,
	faUsers,
	faStar,
	faArrowsRotate,
	faGlobe,
	faCity,
	faCloudUpload,
	faArrowRight,
	faArrowDown,
	faArrowDownFromLine,
	faArrowLeft,
	faArrowRight,
	faArrowTurnUp,
	faArrowUp,
	faArrowUpRightFromSquare,
	faArrowsMaximize,
	faArrowsMinimize,
	faArrowsRotate,
	faArrowsSpin,
	faAsterisk,
	faAtomSimple,
	faBadgeCheck,
	faBadgerHoney,
	faBalloons,
	faBan,
	faBars,
	faBat,
	faBee,
	faBird,
	faBoltAuto,
	faBookSkull,
	faBullseye,
	faCakeCandles,
	faCatSpace,
	faChampagneGlasses,
	faChartLine,
	faCheck,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircleCheck,
	faCircleExclamation,
	faCircleInfo,
	faCircleNotch,
	faCircleRadiation,
	faCircleX,
	faCity,
	faClock,
	faCloudUpload,
	faCloudDownload,
	faCompactDisc,
	faComputer,
	faCopy,
	faCow,
	faCrab,
	faCreditCard,
	faDeer,
	faDeerRudolph,
	faDeleteRight,
	faDeleteLeft,
	faDesktop,
	faDog,
	faDollarSign,
	faDragon,
	faDuck,
	faEarth,
	faElephant,
	faEnvelope,
	faFaceAngry,
	faFaceAngryHorns,
	faFaceAnguished,
	faFaceDiagonalMouth,
	faFaceDisappointed,
	faFaceDisguise,
	faFaceDizzy,
	faFaceExplode,
	faFaceExpressionless,
	faFaceEyesXmarks,
	faFaceHandOverMouth,
	faFaceHeadBandage,
	faFaceIcicles,
	faFaceParty,
	faFaceRaisedEyebrow,
	faFaceSadCry,
	faFaceScream,
	faFaceSwear,
	faFan,
	faFeather,
	faFile,
	faFileImage,
	faFileArrowUp,
	faFileCircleInfo,
	faFilter,
	faFishBones,
	faFlagCheckered,
	faFloppyDisk,
	faGaugeHigh,
	faGear,
	faGhost,
	faGift,
	faGifts,
	faGlobe,
	faHandHoldingSkull,
	faHandshakeSimple,
	faHippo,
	faHome,
	faImage,
	faImagePortrait,
	faLaptop,
	faLightbulb,
	faLoader,
	faLock,
	faMagnifyingGlass,
	faMagnifyingGlassDollar,
	faMagnifyingGlassMinus,
	faMagnifyingGlassPlus,
	faMaximize,
	faMemoCircleCheck,
	faMinimize,
	faMinus,
	faMobile,
	faMoneyBill,
	faMonkey,
	faNarwhal,
	faNewspaper,
	faOtter,
	faPanorama,
	faPartyBell,
	faPartyHorn,
	faPencil,
	faPeopleGroup,
	faPerson,
	faPhone,
	faVideo,
	faPinata,
	faPlus,
	faPrint,
	faRabbit,
	faRadiation,
	faRam,
	faRepeat,
	faRotate,
	faRotateLeft,
	faRotateRight,
	faScythe,
	faShare,
	faSheep,
	faShuffle,
	faSkull,
	faSkullCow,
	faSkullCrossbones,
	faSpiderBlackWidow,
	faSpinner,
	faSpinnerThird,
	faSquarePen,
	faSquirrel,
	faStar,
	faTablePivot,
	faTablet,
	faThumbsUp,
	faTimes,
	faTombstoneBlank,
	faTrash,
	faTriangleExclamation,
	faTurtle,
	faUser,
	faUserPen,
	faUserPlus,
	faUsers,
	faVolcano,
	faWandMagicSparkles,
	thinCircleArrowRight,
	thinCircleCheck,
	thinCircleExclamation,
	thinEarthAmericas,

	faPartyBell,
	faPartyHorn,
	faFaceParty,
	faBalloons,
	faCakeCandles,
	faChampagneGlasses,
	faGifts,
	faPinata,
	faBadgeCheck,
	faFlagCheckered,
	faHandshakeSimple,
	faGift,
	faFeather,
	faMemoCircleCheck,
	faSkullCow,
	faSkullCrossbones,
	faSkull,
	faHandHoldingSkull,
	faBoltAuto,
	faBookSkull,
	faCircleRadiation,
	faSpiderBlackWidow,
	faScythe,
	faRadiation,
	faFishBones,
	faFaceEyesXmarks,
	faFaceExplode,
	faTombstoneBlank,
	faVolcano,
	faLoader,
	faSpinner,
	faSpinnerThird,
	faCircleNotch,
	faAsterisk,
	faAtomSimple,
	faCompactDisc,
	faFan,
	faGear,
	faRotate,
	faGhost,
	faAlicorn,
	faNarwhal,
	faBat,
	faDeer,
	faCow,
	faCatSpace,
	faBadgerHoney,
	faDeerRudolph,
	faDog,
	faElephant,
	faHippo,
	faMonkey,
	faOtter,
	faRabbit,
	faSheep,
	faSquarePen,
	faSquirrel,
	faBird,
	faRam,
	faBee,
	faCrab,
	faDuck,
	faDragon,
	faTurtle,
	faFaceAngryHorns,
	faFaceAngry,
	faFaceAnguished,
	faFaceDiagonalMouth,
	faFaceDisappointed,
	faFaceDisguise,
	faFaceDizzy,
	faFaceExpressionless,
	faFaceHandOverMouth,
	faFaceHeadBandage,
	faFaceIcicles,
	faFaceRaisedEyebrow,
	faFaceScream,
	faFaceSwear,
	faFaceSadCry,
	faGoogle,
	faYelp,
	faFacebook,
	faLinkedin,
	faXTwitter,
	faInstagram,
	faMicrosoft,
	faYoutube,
	faMobile,
	faTablet,
	faLaptop,
	faDesktop,
	faArrowsMaximize,
	faArrowsMinimize,
	faCircleQuestion,
	faLocationDot,
	faPause,
	faMessageQuestion,
	faBlockQuestion,
	faSquareXmark,
	faCircleM,
	faQuestion,
	faBriefcase,
	faCarBurst,
	faCalendar,
].forEach(icon => {
	library.add(icon);
});
