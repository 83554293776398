<template>
	<div class="bg-white p-4 rounded-lg dark:bg-gray-800">
		<ProgressBar v-if="account === null" text="Loading..." class="mb-2" />

		<template v-else-if="account">
			<p class="text-lg text-gray-500 dark:text-gray-200">
				Current Pool Size: {{ account.pool_size }} Numbers (3 included,
				{{ currentExtraLines }} extra)
			</p>

			<form @submit="onSaveExtra">
				<TextFieldInput
					ref="amountRef"
					v-model="additionalNumbers"
					class="w-2/5"
					label="Number of Additional Lines"
					max="10"
					min="0"
					placeholder="0"
					type="number"
				/>
				<BaseButton :disabled="!canSave" class="mt-2" color="primary" type="submit">
					Save
				</BaseButton>
			</form>

			<p style="width: 100%">Disable Company (Permanent)</p>
			<BaseButton outline color="error" @click="toggleAskDisable" class="mt-2">
				Disable
			</BaseButton>
			<BaseDialog v-model="askDisable" alert max-width="600">
				<template #header>Disable Callrail Account</template>

				<BaseAlert type="error">
					This deletes the account's phone numbers and stats, so make sure this is the
					correct agent:
					<b>{{ agent.first_name }} {{ agent.last_name }} :: {{ agent.alias }}</b>
				</BaseAlert>
				<template #actions>
					<BaseButton text color="gray" @click="toggleAskDisable"> Cancel </BaseButton>
					<BaseButton outline color="error" @click="onDisable"> Confirm </BaseButton>
				</template>
			</BaseDialog>
		</template>
		<div v-else>
			<BaseButton outline @click="createCompany"> Create Callrail Company? </BaseButton>
		</div>
	</div>
	<BaseAlert v-if="deletingAccount" class="mt-4 w-full" type="note">
		<ProgressBar text="Deleting Account. this may take a while..." full-width />
	</BaseAlert>

	<BaseAlert v-if="creatingAccount" class="mt-4 w-full" type="note">
		<ProgressBar text="Creating Account. this may take a while..." full-width />
	</BaseAlert>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';

import useAlertStore from '@/stores/alerts';
import useAgentStore from '@/stores/agent/agent';
import useCalltrackingStore from '@/stores/agent/agent-calltracking';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import TextFieldInput from '@/components/ui/TextFieldInput';
import ProgressBar from '@/components/ui/ProgressBar';
import BaseDialog from '@/components/ui/BaseDialog.vue';

const alertStore = useAlertStore();
const agentStore = useAgentStore();
const calltrackingStore = useCalltrackingStore();

const { agent } = storeToRefs(agentStore);
const { account } = storeToRefs(calltrackingStore);

const additionalNumbers = ref(null);
const askDisable = ref(false);
const deletingAccount = ref(false);
const creatingAccount = ref(false);

const currentExtraLines = computed(() => Math.max(0, (account?.value?.pool_size ?? 0) - 3));
const canSave = computed(() => {
	return additionalNumbers.value > -1 && additionalNumbers.value !== currentExtraLines.value;
});

async function loadAccount() {
	try {
		await calltrackingStore.getAccount();
	} catch (error) {
		alertStore.addAlert({ message: 'Could not load account data!', error });
	}
}

async function createCompany() {
	creatingAccount.value = true;
	await calltrackingStore.createAccount();
	creatingAccount.value = false;
}

function onSaveExtra(e) {
	e.preventDefault();
	if (canSave.value) {
		calltrackingStore.update({ pool_size: 3 + Number(additionalNumbers.value) });
	}
}
function toggleAskDisable() {
	askDisable.value = !askDisable.value;
}

async function onDisable() {
	askDisable.value = false;
	deletingAccount.value = true;
	await calltrackingStore.disable();
	deletingAccount.value = false;
}

onMounted(() => {
	loadAccount();
});
</script>

<style scoped></style>
