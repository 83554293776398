<template>
	<ProgressBar v-if="loading" dark text="Retrieving domain list" class="text-xl font-bold" />
	<div v-else class="rounded-md bg-white p-2 dark:bg-gray-800">
		<h3 class="py-2 text-2xl font-semibold text-gray-700 dark:text-gray-100">Active Domains</h3>
		<BaseExpansionList :items="movableDomains" focusable>
			<template #header="domain">
				<div class="flex justify-between items-center gap-2">
					<div class="flex gap-2 items-center">
						<DomainProductTypeBadge small :domain="domain" />
						<span class="font-semibold ml-2 text-base">{{ domain.name }} </span>
					</div>
					<div
						class="rounded-md py-1 px-2 text-sm w-fit border mr-2 font-medium"
						:class="
							domain.active
								? ' border-green-300 dark:border-green-100 bg-green-100 text-green-900'
								: 'border-red-200 dark:border-red-100 bg-red-100 text-red-800'
						"
					>
						{{ domain.active ? 'Active' : 'Canceled' }}
					</div>
				</div>
			</template>
			<template #content="{ name, active }">
				<RegistrarCard v-if="active" v-bind="{ name }" />
				<div v-else class="bg-gray-100 dark:bg-gray-800 p-2">
					<div class="bg-white dark:bg-gray-700 rounded-md text-center p-2">
						This domain is no longer active, but may still be owned by Mirus. Try searching
						for the domain out at the Registrars.
					</div>
				</div>
			</template>
		</BaseExpansionList>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import RegistrarCard from '@/components/MirusControls/RegistrarCard';
import { storeToRefs } from 'pinia';
import useDomainsStore from '@/stores/agent/agent-domains';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import DomainProductTypeBadge from '@/components/domains/DomainProductTypeBadge';

const domainStore = useDomainsStore();
const { movableDomains } = storeToRefs(domainStore);

const loading = ref(true);

onMounted(async () => {
	if (!movableDomains.value) {
		await domainStore.getMovableDomains();
	}
	loading.value = false;
});
</script>
